<template>
  <AddPostForm/>
</template>

<script>
import AddPostForm from "@/components/posts/AddPostForm";

export default {
  components: {
    AddPostForm,
  },
};
</script>