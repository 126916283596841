<template>
  <div>
    <b-form @submit.prevent="addPost">
      <b-form-group label="Nom" label-for="name-input">
        <b-form-input
            id="name-input"
            v-model="$v.post.name.$model"
            type="text"
            required
            placeholder="Renseignez un nom"
            :state="$v.post.name.$dirty ? !$v.post.name.$invalid : null"
        />
        <b-form-invalid-feedback :state="!$v.post.name.$invalid">
          <span v-if="!$v.post.name.required">Vous devez renseigner ce champ</span>
          <span v-else-if="!$v.post.name.name">Ce champ ne peut contenir que des caractères alphabétiques</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group label="Diminutif" label-for="short-name-input">
        <b-form-input
            id="short-name-input"
            v-model="$v.post.shortName.$model"
            type="text"
            required
            placeholder="Renseignez un diminutif"
            :state="$v.post.shortName.$dirty ? !$v.post.shortName.$invalid : null"
        />
        <b-form-invalid-feedback :state="!$v.post.shortName.$invalid">
          <span v-if="!$v.post.shortName.required">Vous devez renseigner ce champ</span>
          <span v-else-if="!$v.post.shortName.name">Ce champ ne peut contenir que des caractères alphabétiques</span>
          <span v-else-if="!$v.post.shortName.maxLength">Longueur maximale dépassée (10 caractères)</span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-button type="submit" variant="primary" :disabled="$v.$invalid">
        Envoyer
      </b-button>
    </b-form>
  </div>
</template>

<script>
import {maxLength, required} from "vuelidate/lib/validators";
import {name} from "@/validators";

export default {
  data() {
    return {
      post: {
        name: "",
        shortName: "",
      },
    };
  },
  validations: {
    post: {
      name: {required, name},
      shortName: {required, name, maxLength: maxLength(10)},
    },
  },
  methods: {
    async addPost() {
      try {
        await this.$store.dispatch("posts/addPost", this.post);
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title: "Une erreur est survenue lors de la création du poste",
          text: e.message,
        });
        return;
      }

      await this.$swal.fire({
        icon: "success",
        title: "Poste créé",
      });
      await this.$router.push({name: "posts"});
    },
  },
};
</script>
